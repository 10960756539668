import React, { useState, useEffect } from "react";
import { getHygienes } from "../../../../services/landingPageSearch";
import { toast } from "react-toastify";
import { IAppState } from "../../../../store";
import * as _ from "lodash";
import configs from "../../../../configs/apiConfigs";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import { addFilterItems, addLanguage } from "../../../../store/filters/actions";
import { useHistory } from "react-router-dom";
import { searchFilter } from "../../../../store/v2/searchFilter/actions";
import { fetchClinicList } from "../../services/AppointmentBook";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../../../store/loadingIndicator/actions";
import { addClinicProviderList } from "../../../../store/clinicAndProviderList/actions";
import {
  clinicAndProviderList,
  existingClinicProvider,
} from "../../../../services/landingPageSearch";
import moment from "moment";
import { addSlotData } from "../../../../store/slot/actions";
import {
  patientblocksearch,
  appointmentSlot,
} from "../../../../services/landingPageSearch";
import Pagination from "react-js-pagination";
import { showAddress } from "../../components/ShowAddress/ShowAddress";
function ClinicList(props: any) {
  const clinicProviderList: any = useSelector(
    (state: IAppState | any) => state.clinicProviderList.clinicProviderList
  );
  const loading = useSelector((state: IAppState) => state?.loading?.pending);
  const [clinicList, setClinicList] = useState([] as any);
  const [isSeeMore, setIsSeeMoe] = useState(false);
  const [hygieneList, setHygieneList] = useState([]);
  const [isLoading, setIsLoading] = useState(loading);
  const dispatch = useDispatch();
  const history = useHistory();
  const [iFram, setiFram] = useState(false);
  const searchFilterData = useSelector(
    (state: IAppState) => state.searchFilter.searchFilterData,
    shallowEqual
  );
  const [totalCount, setTotalCount] = useState(0);
  const [apiClinicListRequest, setApiClinicListRequest]: any = useState();
  const [apiClinicProviderRequest, setApiClinicProviderRequest]: any =
    useState();
  const [apiAppointmentSlotRequest, setAppointmentSlotRequest]: any =
    useState();
  const [totalCountProvider, setTotalCountProvider] = useState(0);
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(25);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(25);
  useEffect(() => {
    if (new URLSearchParams(history.location.search).get("iframe") === "yes") {
      setiFram(true);
    }
    setIsLoading(true);
    fetchClinicListApi();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);
  useEffect(() => {
    if (
      clinicProviderList.cliniclist.length > 0 &&
      JSON.stringify(clinicList) !==
        JSON.stringify(clinicProviderList.cliniclist)
    ) {
      getHygiene();
      setClinicList(clinicProviderList.cliniclist);
      setIsLoading(false);
    }
    if (clinicProviderList.cliniclist.length === 0) {
      setClinicList([]);
      setIsLoading(false);
    }
  }, [clinicProviderList.cliniclist]);

  const fetchClinicListApi = (pageNumber?: any) => {
    const {
      oldSpeciality,
      newSpeciality,
      location,
      date,
      howToPay,
      gender,
      distance,
      languages,
      clinicId,
      providerId,
      patientUniqueId,
      clinicPage,
      results,
    } = searchFilterData;
    let latitude: any = "",
      longitude: any = "";
    if (location !== "") {
      latitude = location.latitude;
      longitude = location.longitude;
    }
    let langIds: any = [];
    if (languages && languages.length > 0) {
      langIds = languages.map((item: any) => {
        return item.value;
      });
    }
    let request = {
      location: {
        latitude: latitude,
        longitude: longitude,
        specialityId: newSpeciality,
        searchdate: date === null ? "" : date,
        radiuskm: distance,
        insuranceProviderId:
          howToPay.type === "insurance" ? howToPay.value : "",
        acceptAssignment: howToPay.type === "cash" ? "no" : "",
        socialAssistTypeID:
          howToPay.type === "socialAssistance" ? howToPay.value : "",
        clinicId: clinicId,
        patientUniqueId: patientUniqueId,
        page: pageNumber ? pageNumber : 1,
        results: 25,
      },
    };
    let isApiCall: boolean = false;
    if (JSON.stringify(apiClinicListRequest) !== JSON.stringify(request)) {
      setApiClinicListRequest(request);
      isApiCall = true;
    }
    if (isApiCall) {
      dispatch(fetchLoadingPending());
      fetchClinicList(request)
        .then((success) => {
          dispatch(fetchLoadingSuccess(false));
          if (success && success.status && success.status.error === false) {
            console.log("successs", success);
            // setClinicList(success.clinic);
            if (success && success.total) {
              setTotalCount(success.total);
            }
            dispatch(
              addClinicProviderList({
                ...clinicProviderList,
                clinicList: _.uniqBy(success.clinic, "id"),
                clinicTotalCount: success.total ? success.total : totalCount,
              })
            );
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }
        })
        .catch((err) => {
          dispatch(fetchLoadingSuccess(false));
          dispatch(
            addClinicProviderList({
              ...clinicProviderList,
              clinicList: [],
              clinicTotalCount: 0,
            })
          );
          dispatch(
            searchFilter({
              ...searchFilterData,
              clinicPage: 1,
            })
          );
          console.log(err);
        });
    }
  };

  const clickClinicBookAppointment = (clinic: any) => {
    // let providerList = clinicProviderList.providerList.filter((item:any)=> item.clinic_uniqueId === clinic.uniqueId);
    // dispatch(addFilterItems({
    //     ...filter.filterItems,
    //     // doctor: providerList,
    //     selectedClinic: clinic.uniqueId,
    //     clinicId: clinic.id,
    //     speciality: '',
    //     date: null,
    //     gender: [],
    //     language: [],
    //     distance: '',
    //     selectedProvider: '',
    //     location: ''
    // }));

    /* DO NOT REMOVE CODE */
    // dispatch(searchFilter({
    //     ...searchFilterData,
    //     clinicId: clinic.id
    // }));
    // getClinicProviderList(clinic.id);
    // props.bookAppointmentClick();

    history.push({
      pathname:
        "/" +
        clinic.locality +
        "/" +
        clinic.name.split(" ").join("-") +
        "/" +
        clinic.uniqueId +
        "/c",
      state: { iFrame: iFram, isInternal: true },
    });
  };
  const getHygiene = () => {
    getHygienes().then((success: any) => {
      try {
        if (
          success &&
          success.response &&
          success.response.data &&
          success.response.status === 200
        ) {
          if (
            success.response.data &&
            success.response.data.status.error === false
          ) {
            setHygieneList(success.response.data.hygieneFeatures);
          } else {
            if (
              success.response.data &&
              success.response.data.status.error === true
            ) {
              toast.error(success.response.data.status.msg);
            }
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    });
  };

  const getClinicProviderList = (cId: any) => {
    const {
      newSpeciality,
      location,
      date,
      howToPay,
      patientUniqueId,
      page,
      results,
      languages,
      distance,
      gender,
      clinicId,
      providerId,
    } = searchFilterData;
    let latitude: any = "",
      longitude: any = "",
      langIds: any = [],
      clinic: any = "";
    if (location !== "") {
      latitude = location.latitude;
      longitude = location.longitude;
    }
    if (languages && languages.length > 0) {
      langIds = languages.map((item: any) => {
        return item.value;
      });
    }
    let request = {
      location: {
        latitude: latitude,
        longitude: longitude,
        specialityId: newSpeciality,
        searchdate: date !== null ? date : "",
        radiuskm: distance,
        gender: gender ? gender.toString() : "",
        languages: langIds.toString(),
        insuranceProviderId:
          howToPay.type === "insurance" ? howToPay.value : "",
        acceptAssignment: howToPay.type === "cash" ? "no" : "",
        socialAssistTypeID:
          howToPay.type === "socialAssistance" ? howToPay.value : "",
        clinicId: cId,
        providerId: providerId,
        patientUniqueId: patientUniqueId,
        page: 1,
        results: results,
      },
    };
    let isApiCall: boolean = false;
    if (JSON.stringify(apiClinicProviderRequest) !== JSON.stringify(request)) {
      setApiClinicProviderRequest(request);
      isApiCall = true;
    }
    if (isApiCall) {
      dispatch(fetchLoadingPending());
      try {
        clinicAndProviderList(request).then((success: any) => {
          if (
            success &&
            success.response &&
            success.response.data &&
            success.response.status === 200
          ) {
            if (
              success.response.data &&
              success.response.data.status.error === false
            ) {
              setTimeout(() => {
                dispatch(fetchLoadingSuccess(false));
              }, 2000);
              if (success.response.data && success.response.data.total) {
                setTotalCountProvider(success.response.data.total);
              }
              dispatch(
                addClinicProviderList({
                  ...clinicProviderList,
                  clinicList: _.uniqBy(success.response.data.clinic, "id"),
                  providerList: success.response.data.doctors,
                  providerTotalCount: success.response.data.total
                    ? success.response.data.total
                    : totalCountProvider,
                })
              );
              // appointmentSlotApi(success.response.data.doctors);
            } else if (
              success.response.data &&
              success.response.data.status.error === true
            ) {
              dispatch(
                addClinicProviderList({
                  ...clinicProviderList,
                  clinicList: [],
                  providerList: [],
                  providerTotalCount: 0,
                  page: 1,
                })
              );
              console.log("error");
              dispatch(fetchLoadingSuccess(false));
            }
          }
        });
      } catch (error) {
        toast.error("Something went wrong.");
        console.log("error", error);
        dispatch(fetchLoadingSuccess(false));
      }
    }
  };

  const appointmentSlotApi = (providerList?: any, currentWeek?: any) => {
    var request: any = [];
    let startDate: any = searchFilterData.date
      ? searchFilterData.date
      : moment().format("YYYY-MM-DD");
    let endDate: any = searchFilterData.date
      ? moment(searchFilterData.date).add("days", 4).format("YYYY-MM-DD")
      : moment().add("days", 4).format("YYYY-MM-DD");
    if (providerList.length > 0) {
      providerList.map((item: any) => {
        if (item.sharecalendar && item.sharecalendar.calender) {
          request.push({
            doctorId: item.id,
            clinicId: item.clinic_id,
            start_date: startDate,
            end_date: endDate,
          });
        }
      });
      if (request.length > 0) {
        let data = { appointmentData: request };
        let isApiCall: boolean = false;
        if (
          JSON.stringify(apiAppointmentSlotRequest) !== JSON.stringify(data)
        ) {
          setAppointmentSlotRequest(data);
          isApiCall = true;
        }
        if (isApiCall) {
          dispatch(fetchLoadingPending());
          appointmentSlot(data).then((success: any) => {
            try {
              if (
                success &&
                success.response &&
                success.response.data &&
                success.response.status === 200
              ) {
                if (
                  success.response.data &&
                  success.response.data.status.error === false
                ) {
                  dispatch(fetchLoadingSuccess(false));
                  var result = groupBy(
                    success.response.data.appointments,
                    function (item: any) {
                      return [item.doctorid, item.clinicid];
                    }
                  );
                  let data: any = [];
                  result.map((item: any) => {
                    let groupData = _.groupBy(item, "startdate");
                    data.push(groupData);
                  });
                  dispatch(addSlotData({ slot: data }));
                } else {
                  if (
                    success.response.data &&
                    success.response.data.status.error === true
                  ) {
                    toast.error(success.response.data.status.msg);
                  }
                  dispatch(fetchLoadingSuccess(false));
                }
              }
            } catch (error) {
              toast.error("Something went wrong.");
              console.log("error", error);
              dispatch(fetchLoadingSuccess(false));
            }
          });
        } else {
          dispatch(fetchLoadingSuccess(false));
        }
      }
    }
  };
  const groupBy = (array: any, f: any) => {
    var groups: any = {};
    array.forEach(function (o: any) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  };

  useEffect(() => {
    const start = (page * results) / results;
    const end =
      clinicProviderList.clinicTotalCount > (page * results) / results + 24
        ? (page * results) / results + 24
        : clinicProviderList.clinicTotalCount;
    setStart(start);
    setEnd(end);
    setTotalCount(clinicProviderList.clinicTotalCount);
    setPage(clinicProviderList.clinicTotalCount <= results ? 1 : page);
  }, [clinicProviderList.clinicTotalCount]);
  const handlePageChange = (pageNUmber: any) => {
    if (page !== pageNUmber) {
      setStart(pageNUmber * results - (results - 1));
      setEnd(
        pageNUmber * results > totalCount ? totalCount : pageNUmber * results
      );
      setPage(pageNUmber);
      dispatch(
        searchFilter({
          ...searchFilterData,
          clinicPage: pageNUmber,
        })
      );
      fetchClinicListApi(pageNUmber);
    }
  };

  return (
    <>
      <div aria-labelledby="profile-tab">
        <div className="provider-list-cal">
          <div className="d-md-flex justify-content-between calender-view-sec mt-4">
            {/* <h2>{clinicList && clinicList.length > 0 && clinicList.length} Clinics</h2> */}
            {totalCount !== 0 ? (
              <div className="text-muted align-self-center mt-4 mb-3 ">
                {start}-{end} of {totalCount} Clinics
              </div>
            ) : (
              <div className="text-muted align-self-center mt-4 mb-3">
                Clinics
              </div>
            )}
          </div>
          {clinicList && clinicList.length > 0 ? (
            <ul className="provider-list-box list-of-clinic px-md-4">
              {clinicList.map((item: any, indx: number) => {
                let description: string = "";
                if (item && item.description) {
                  description = item.description;
                }
                return (
                  <li
                    key={indx + Math.random() + "provider"}
                    className="d-md-flex justify-content-between"
                    // onMouseEnter={(e) => {
                    //     if (this.map) {
                    //         this.drawRouteOnHover(item);
                    //     }
                    // }} onMouseLeave={(e) => {
                    //     if (this.currentDirectionRenderer) {
                    //         this.currentDirectionRenderer.setMap(null);
                    //         //@ts-ignore
                    //         google.maps.event.trigger(this.map, 'resize');
                    //     }
                    // }}
                  >
                    <div className="left-sec">
                      <div className="media d-flex">
                        <div className="media-img me-4 text-center">
                          <div className="img-box">
                            {item.logo && item.logo.name && (
                              <img
                                src={
                                  configs.IMAGE_URL +
                                  "clinic/" +
                                  item.logo.name +
                                  "-200x200." +
                                  item.logo.type
                                }
                                className="img-fluid"
                              />
                            )}
                          </div>
                          <button
                            onClick={() => {
                              history.push({
                                pathname:
                                  "/" +
                                  item.locality +
                                  "/" +
                                  item.name.split(" ").join("-") +
                                  "/" +
                                  item.uniqueId +
                                  "/c",
                                state: { iFrame: iFram, isInternal: true },
                              });
                            }}
                            className="btn btn-link px-0 mx-auto"
                          >
                            View profile
                          </button>
                          {/* <span className="badge badge-success">preferred</span> */}
                        </div>
                        <div className="media-body">
                          {/* <div className="pro-sub-txt"><img src={require("./images/highlyRecommended.svg")} className="me-2" />Highly Recommended</div> */}
                          <div className="pro-main-txt my-1">{item.name}</div>
                          <div className="pro-add my-1">
                            {showAddress(item)}
                          </div>
                          <div className="my-1">
                            {item.rating > 0 && item.reviewCount && (
                              <span className="display-inline-bolck me-3">
                                <img
                                  src={require("../../assets/images/star-ratings.svg")}
                                  className="me-2 img-fix"
                                />
                                {item.rating + " (" + item.reviewCount + ")"}
                              </span>
                            )}
                            {item && item.distance && item.distance > 0 && (
                              <span className="display-inline-bolck me-3">
                                <img
                                  src={require("../../assets/images/miles.svg")}
                                  className="me-2 img-fix"
                                />{" "}
                                {parseFloat(item.distance).toFixed(1)} Km
                              </span>
                            )}
                            {item.HygieneFeatures && (
                              <span className="display-inline-bolck">
                                <img
                                  src={require("../../assets/images/hygiene.svg")}
                                  className="me-2 img-fix"
                                />
                                {item.HygieneFeatures.length > 0
                                  ? (item.HygieneFeatures.length /
                                      hygieneList.length) *
                                    100
                                  : 0}
                                % hygiene
                              </span>
                            )}
                          </div>
                          <p className="my-1">
                            <div>
                              {isSeeMore
                                ? description
                                : description.length > 100
                                ? description.substring(0, 100) + "..."
                                : description}
                            </div>
                            {description.length > 100 ? (
                              <button
                                className="location-title btn mx-auto p-0"
                                style={{ color: "blue" }}
                                onClick={() => {
                                  setIsSeeMoe(!isSeeMore);
                                }}
                              >
                                {!isSeeMore ? "See More" : "See Less"}
                              </button>
                            ) : null}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="right-sec text-end align-self-start align-self-xl-center d-flex d-md-block">
                      <div className="cd-dr-img">
                        {item &&
                          item.doctorphotos &&
                          item.doctorphotos.length > 0 &&
                          item.doctorphotos.map((value: any, index: number) => {
                            if (index >= 3) {
                              return null;
                            }
                            if (
                              value.photo &&
                              value.photo.name &&
                              value.photo.type
                            ) {
                              return (
                                <div
                                  key={index + Math.random()}
                                  className="img-box"
                                >
                                  <img
                                    src={
                                      configs.IMAGE_URL +
                                      "doctor/" +
                                      value.photo.name +
                                      "-200x200." +
                                      value.photo.type
                                    }
                                    className="img-fluid"
                                  />
                                </div>
                              );
                            }
                          })}
                      </div>
                      <button
                        onClick={() => {
                          clickClinicBookAppointment(item);
                        }}
                        className="border-btn btn"
                      >
                        <img
                          src={require("../../assets/images/view-availability.svg")}
                          className="me-2"
                        />
                        Book appointment
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : isLoading ? null : (
            <h3 className="px-4 pt-3">No result found.</h3>
          )}
        </div>
        {clinicList && clinicList.length > 0 && (
          <div className="d-flex justify-content-end px-4 pt-3">
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              activePage={page}
              itemsCountPerPage={results}
              totalItemsCount={Number(totalCount)}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ClinicList;
